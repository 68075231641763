const user =  {
  state: {
    id: '',
    username: '',
    realname: '',
    roleCode: '',
    token: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    }
  }
}

export default user;