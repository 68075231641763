import Vue from 'vue'
import VueRouter from 'vue-router'
import global from '@/common/global';

Vue.use(VueRouter)

/**
 * @Description: 修复路由重复报错
 * @Author: Niklaus
 * @Date: 2021-09-29 17:20:00
 */
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('./../views/Index.vue')
  },
  {
    path :'/login',
    name: 'login',
    component: () => import('./../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('./../views/Register.vue')
  },
  {
    path:'/home',
    name:'home',
    component: () => import('./../views/Home.vue'),
    children: [
      {
        path: '/bind',
        name: 'bind',
        component: () => import('../views/Bind.vue')
      },
      {
        path: '/onGoing',
        name: 'onGoing',
        component: () => import('./../views/OnGoing.vue')
      },
      {
        path: '/deal',
        name: 'deal',
        component: () => import('./../views/Deal.vue')
      },
      {
        path: '/participate',
        name: 'participate',
        component: () => import('./../views/Participate.vue')
      },
      {
        path: '/bidHall',
        name: 'bidHall',
        component: () => import('./../views/BidHall.vue')
      },
      {
        path: '/bidQuotaHall',
        name: 'BidQuotaHall',
        component: () => import('./../views/BidQuotaHall.vue')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('./../views/User.vue')
      },
      {
        path:'/tab',
        name:'tab',
        component: () => import('./../views/Tab.vue')
      },
      {
        path: '/project',
        name: 'project',
        component: () => import('./../views/Project.vue')
      },
      {
        path: '/confirmResult',
        name: 'confirmResult',
        component: () => import('./../views/ConfirmResult.vue')
      },
    ]
  },
  {
    path: '/webauthorize2',
    name: 'webauthorize2',
    component: () => import('./../views/WebAuthorize.vue')
  },
	{
		path: '/noauth',
		name: 'noauth',
		component: () => import('./../views/NoAuth.vue')
	},
  {
    path: '*',
    name: '404',
    component: () => import('./../views/404.vue')
  },
  {
    path: '/warn',
    name: 'warn',
    component: () => import('./../views/Warn.vue')
  },
  {
    path: '/testScan',
    name: 'testScan',
    component: () => import('./../views/TestScan.vue')
  },
  {
    path: '/webauthorize',
    name: 'webauthorize',
    component: () => import('./../views/WarnTurn.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to,from,next)=>{
  /** 校验微信环境 */
  let uAgent = navigator.userAgent.toLowerCase();
  let devFlag = 'http://127.0.0.1:9999'==global.gateway || true;
  let token= Vue.ls.get("token")
  if(to.name == 'login' || to.name=='warn'||to.name=='webauthorize'){
    next(true)
  }else if(token) {
    next(true)
  }else {
    next({ name : 'login' })
  }
  // else if(!(/micromessenger/.test(uAgent))){
  //   if(to.name=='warn'){
  //     next(true)
  //   }else{
  //     next({ name: 'warn' })
  //   }
  // }
  // else{
  //   next(true)
  // }
});

export default router
