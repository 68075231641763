/*
 * @Descripttion: vue-ls配置
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-24 15:18:09
 */
export default {
  storeageOptions : {
    namespace: '',
    name: 'ls',
    storage: 'local'
  }
}