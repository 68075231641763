/*
 * @Descripttion:
 * @version:
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2020-10-13 08:27:35
 */

// import area from "@/common/area"
export default {
    //prod-corpId-服务商-嘟聪
    corpId:"wwfbbc8d23ebbc2b42",
    //prod-corpsecret
    corpSecret:"5zU2eOu-_cxdlfa_tf6Pgt1VR2cQ9YLVwUi6eMRWFARtePy1UZ2WsMFtI-J0VhcU",
    //prod-企业微信-竞价供应商应用 suite id
    suiteId: 'wwf338746557f44bef',
    //prod-企业微信-竞价供应商应用 suite secret
    suiteSecret: 'o0lXH_KcPMI0Xgn2THMydfukFiXUKGjGpdqwy0N4d8Q',

    //test-corpId-服务商-嘟聪
    // corpId:"wwfbbc8d23ebbc2b42",
    //test-企业微信-竞价供应商应用 suite id
    // suiteId: 'ww2f7f813d003e202e',
    //test-企业微信-竞价供应商应用 suite secret
    // suiteSecret: '6heiGGUJcMVMXCwhDgtiNSCYCsESuv1u42_Ns5HC7LU',

	gateway:"/gateway",
    //本地开发
    //  gateway: "http://127.0.0.1:9999",
    //企业微信-通讯录secret
    // addressBookSecret: 'ghKncZum8e3CZ0F3c7vcY33Qh6dYoM50RHrKh39gLOk',
    randomStr: function (num) {
        num = num || 16;
        let result = '';
        let str = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
        for (let index = 0; index < num; index++) {
            result += str.charAt(Math.floor(Math.random() * str.length))

        }
        return result
    },
    /**
     * 将 Date 转化为指定格式的String
     * @param {*} date
     * @param {*} fmt
     */
     formatDateStr(date, fmt) {
        let o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (let k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    },    
}
